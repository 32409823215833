<template>
  <section class="app-main" :class="[{ 'isV5': isV5 }, { 'isShwBanner': isShwBanner }]">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view v-if="!$route.meta.link" :key="key" />
      </keep-alive>
    </transition>
    <iframe-toggle />
  </section>
</template>

<script>
import iframeToggle from "./IframeToggle/index"
import Cookies from 'js-cookie'

export default {
  name: 'AppMain',
  components: { iframeToggle },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    },

    isV5() {
      return this.$route.path.indexOf('V5') > -1
    },

    isShwBanner: {
      get() {
        return this.$store.state.settings.bannerIsShow && JSON.parse(Cookies.get('isShowBanner'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px);
  max-width: 1320px;
  min-width: 1000px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  &:has(.full-width-page) {
    width: 100%;
    max-width: 100%;
  }
}

.fixed-header+.app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
    overflow: auto;

    &.isV5 {
      // min-height: calc(100vh - 98px);

      >.app-container {
        height: calc(100vh - 98px);
      }

      &.isShwBanner {
        min-height: calc(100vh - 172px);

        >.app-container {
          height: calc(100vh - 172px);
        }
      }
    }

    &.isShwBanner {
      min-height: calc(100vh - 158px);

      >.app-container {
        height: calc(100vh - 158px);
      }
    }

    &:has(.views-container) {
      max-width: 1500px;
    }
  }

  .fixed-header+.app-main {
    padding-top: 84px;
  }

  .fixed-header+.app-main.isV5 {
    padding-top: 98px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 17px;
  }
}
</style>
